<template>
	<main class="main">
		<Slider></Slider>
		<section class="container" v-if="blocks">
			<div class="main__box">
				<Advantages
					:data="{ first: blocks[0], second: blocks[1], third: blocks[2] }"
				></Advantages>
			</div>
		</section>

		<section class="container" v-if="blocks">
			<div class="main__box">
				<Info :data="[blocks[3], blocks[4]]"></Info>
			</div>
		</section>

		<section class="main__box">
			<div class="container">
				<h3 class="main__title">Последние новости</h3>
			</div>
			<SliderNews></SliderNews>
		</section>
	</main>
</template>

<script>
import Slider from '@/components/SliderMain.vue'
import Advantages from '@/components/PartnerAdvantages.vue'
import Info from '@/components/Info.vue'
import SliderNews from '@/components/SliderNews.vue'

export default {
	name: 'PartnerHome',

	components: {
		Slider,
		Advantages,
		Info,
		SliderNews,
	},

	computed: {
		blocks() {
			const blocks = this.$store.getters.BLOCKS

			return blocks[this.$options.name]
		},
	},

	mounted() {
		this.$store.dispatch('GET_BLOCKS', {
			page: this.$route.fullPath,
			block: this.$options.name,
		})
	},
}
</script>
